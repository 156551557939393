.modelgallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(249px, 1fr));
  grid-auto-rows: minmax(256px, auto);
  justify-content: start;
  grid-gap: 10px;
  margin-top: 0px;
  margin-left: 120px;
  margin-right: 120px;
  margin-bottom: 20px;

  /* Adjust grid layout for smaller screens */
  @media (max-width: 768px) {}
}

.modelgallery-grid img {
  max-width: 256px;
  max-height: 256px;
  object-fit: contain;
}

.modelgallery-name {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 0px;
  font-weight: 100;
}

.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}

.overlay-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
}

.overlay-content img {
  width: auto;
  height: 90vh;
  display: block;
  border-radius: 4px;
  margin-top: 10px
}

.overlay-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -8px;
  padding: 0 16px;
}

.overlay-buttons button {
  background-color: transparent;
  color: #fff;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.overlay-buttons button:hover {
  opacity: 0.8;
}

/* Adjust modelgallery size for smaller screens */
@media (max-width: 768px) {
  .modelgallery-grid img {
    /* max-width: 14px;
    max-height: 21px; */
  }

  .modelgallery-grid {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    grid-auto-rows: minmax(128px, auto);
    margin: 10px;
  }

  .overlay-content img {
    width: fit-content;
    height: fit-content;
    display: block;
    border-radius: 4px;
  }
}