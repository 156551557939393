.thumbnail-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-auto-rows: minmax(256px, auto);
  justify-content: start;
  grid-gap: 10px;
  /* margin: 20px; */
  margin-top: 10px;
  margin-left: 120px;
  margin-right: 120px;
  margin-bottom: 20px;
}

.thumbnail-grid img {
  max-width: 256px;
  max-height: 384px;
  object-fit: contain;
}

/* Adjust thumbnail size for smaller screens */
@media (max-width: 768px) {
  .thumbnail-grid img {
    /* max-height: 80vh;
    max-width: 40vw; */

  }

  .thumbnail-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    grid-auto-rows: minmax(128px, auto);
    justify-content: start;
    grid-gap: 5px;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin: 10px;
  }
}